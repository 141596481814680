@import '~antd/dist/reset.css';
@import '/node_modules/flag-icons/css/flag-icons.min.css';

.leaflet-container {
    width: 100%;
    height: 400px;
}

.leaflet-priorityMarker {
    z-index: 1360 !important;
}

.ant-page-header-heading-title {
    font-size: 32px
}

.ant-page-header-heading-sub-title {
    align-self: flex-end;
}

.muted {
    opacity: 0.3;
}

.ant-select-dropdown {
    z-index: 100000;
}

span.ant-transfer-list-header-dropdown {
    display: none;
}

.ant-layout {
    display: flex;
    flex: auto;
    flex-direction: column;
    min-height: 0;
    background: #fafafa;
}

.ant-layout-header { 
    padding: 0 20px;
    padding-inline: 20px !important;
}

.ant-card {
    border-radius: 4px;
}

.ant-card-small > .ant-card-head {
    font-size: 12px;
}

.ant-card-head-title {
    display: inline-block;
    flex: 1 1;
    padding: 16px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ant-card-bordered {
    border: 1px solid #dedede;
}

.ant-menu {
    font-variant: tabular-nums;
    /*line-height: 1.5715;*/
    font-feature-settings: "tnum","tnum";
    color: rgba(0,0,0,.85);
    font-size: 12px;
    line-height: 0;
    text-align: left;
    list-style: none;
}

.ant-menu-item-selected, .ant-menu-item-selected a, .ant-menu-item-selected a:hover {
    color: #0070cc;
}

.ant-menu-item .ant-menu-item-icon, .ant-menu-item .anticon, .ant-menu-submenu-title .ant-menu-item-icon, .ant-menu-submenu-title .anticon {
    min-width: 14px;
    font-size: 12px;
    transition: font-size .15s cubic-bezier(.215,.61,.355,1),margin .3s cubic-bezier(.645,.045,.355,1),color .3s;
    vertical-align: -4px !important;
}

.ant-form-item-label > label {
    position: relative;
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    height: 32px;
    color: rgba(0,0,0,.85);
    font-size: 12px;
}

.ant-btn {
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    box-shadow: 0 2px 0 rgba(0,0,0,.015);
    cursor: pointer;
    transition: all .3s cubic-bezier(.645,.045,.355,1);
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: manipulation;
    height: 32px;
    padding: 5.6px 15px;
    font-size: 12px;
    border-radius: 0;
    color: rgba(0,0,0,.85);
    border: 1px solid #0070cc;
    background: #fafafa;
}

.ant-btn-text {
    color: rgba(0,0,0,.85);
    border-color: transparent;
    background: transparent;
    box-shadow: none;
}

.ant-btn-circle {
    min-width: 32px;
    padding-right: 0;
    padding-left: 0;
    text-align: center;
    border-radius: 50%;
}

.ant-btn .anticon {
    transition: margin-left .3s cubic-bezier(.645,.045,.355,1);
}

.ant-btn-icon-only > .anticon {
    display: flex;
    justify-content: center;
}

.ant-btn-icon-only {
    width: 32px;
    padding: 2.4px 0;
    font-size: 16px;
    border-radius: 2px;
    border-color: #d9d9d9;
    vertical-align: -3px;
}

.ant-btn > .anticon {
    line-height: 1;
}

.anticon {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -.125em;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.anticon svg {
    display: block;
}

.ant-form-item-explain, .ant-form-item-extra {
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    line-height: 1.5715;
}

.ant-picker {
    box-sizing: border-box;
    margin: 0;
    color: rgba(0,0,0,.85);
    font-size: 12px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: "tnum","tnum";
    font-feature-settings: "tnum","tnum";
    /* padding: 6px 11px; */
    position: relative;
    display: inline-flex;
    align-items: center;
    background: #fff;
    border: 1px solid #dedede;
      border-top-color: rgb(222, 222, 222);
      border-right-color: rgb(222, 222, 222);
      border-right-width: 1px;
      border-bottom-color: rgb(222, 222, 222);
      border-left-color: rgb(222, 222, 222);
    border-radius: 0;
    transition: border .3s,box-shadow .3s;
}

.ant-picker-input {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 100%;
}

.ant-input {
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: "tnum","tnum";
    font-feature-settings: "tnum","tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 5.6px 11px;
    color: rgba(0,0,0,.85);
    font-size: 12px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #dedede;
    border-radius: 0;
    transition: all .3s;
}

.ant-select-selector {
    border-radius: 0 !important;
}

.ant-picker-input > input {
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    color: rgba(0,0,0,.85);
    font-size: 12px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border-radius: 0;
    transition: all .3s;
    flex: auto;
    min-width: 1px;
    height: auto;
    padding: 0;
    background: transparent;
    border: 0;
}

.ant-table.ant-table-small {
    font-size: 12px;
}

.ant-table {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0,0,0,.85);
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: "tnum","tnum";
    font-feature-settings: "tnum","tnum";
    position: relative;
    font-size: 12px;
    background: #fff;
    border-radius: 0;
}

.ant-tabs-tab {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 12px 0;
    font-size: 12px;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    margin: 0;
    padding: 10px 16px;
    border: 1px solid #dedede;
      border-bottom-color: rgb(222, 222, 222);
    transition: all .3s cubic-bezier(.645,.045,.355,1);
}

.ant-modal-title {
    margin: 0;
    color: rgba(0,0,0,.85);
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    word-wrap: break-word;
}

.ant-form-item {
    box-sizing: border-box;
    padding: 0;
    color: rgba(0,0,0,.85);
    font-size: 12px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: "tnum","tnum";
    font-feature-settings: "tnum","tnum";
    margin: 0 0 16px;
    vertical-align: top;
    transition: margin-bottom .3s linear 17ms;
}

.ant-alert {
    margin-bottom: 15px;
}

.ant-popover {
    z-index: 103000 !important;
}

.ant-badge-count {
	box-shadow: 0 0 0 0 #000;
	padding-left: 5px !important;
	padding-right: 5px !important;
	padding-bottom: 16px !important;
	padding-top: 1px !important;
}

[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  overflow: hidden;
  background: #f7f7f7;
  border: 0px;
  border-radius: 2px;
}

.ant-message {
    z-index: 1000000;
}

.ant-tabs-nav {
    margin: 0px !important;
}

.ant-typography-edit-content {
    margin-left: 10px;
}

.ant-notification {
    z-index: 100000 !important;
}

/* set responsive range picker*/
.ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
    display: inline-flex;   
    animation: none;   
    flex-wrap: nowrap;  
    direction: ltr;   
    @media (max-width: 768px) { 
        display: inline-grid;
    }
}
/* set responsive range picker*/

.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}